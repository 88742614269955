
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

export default defineComponent({
  name: 'ProfitRecord',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'orderCode',
              label: '交易单号'
            },
            {
              field: 'agentId',
              label: '代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'startDate',
              label: '开始时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            },
            {
              field: 'endDate',
              label: '结束时间',
              type: 'date',
              pattern: 'YYYYMMDD'
            }
          ]
        },
        remote: {
          action: https?.payment.pageProfitReward
        }
      },
      cols: [
        {
          field: 'col-basic',
          label: '基本信息',
          width: 300,
          group: [
            {
              field: 'profitOrderCode',
              label: '分润流水',
              ability: 'copy'
            },
            {
              field: 'tradeOrderCode',
              label: '交易订单',
              ability: 'copy'
            },
            {
              field: 'tradeAmt',
              label: '交易金额'
            },
            {
              field: 'statusDesc',
              label: '状态'
            }
          ]
        },
        {
          field: 'col-amt',
          label: '交易分润',
          width: 120,
          group: [
            {
              field: 'profitDueAmt',
              label: '应发'
            },
            {
              field: 'profitActAmt',
              label: '实发'
            }
          ]
        },
        {
          field: 'col-extra',
          label: '额外手续费',
          width: 120,
          group: [
            {
              field: 'extDueAmt',
              label: '应发'
            },
            {
              field: 'extActAmt',
              label: '实发'
            }
          ]
        },
        {
          field: 'col-trade',
          label: '交易信息',
          group: [
            {
              field: 'tradeBackerNo',
              label: '渠道',
              options: mapper?.device.backerNo
            },
            {
              field: 'tradeRate',
              label: '费率'
            },
            {
              field: 'tradePosCode',
              label: '机具号'
            },
          ]
        },
        {
          field: 'col-benefit-agent',
          label: '上级代理',
          group: [
            {
              field: 'profitUserId',
              label: '代理ID'
            },
            {
              field: 'profitUserPhone',
              label: '手机号'
            },
            {
              field: 'profitRate',
              label: '结算价'
            },
            {
              field: 'extFee',
              label: '额外手续费'
            }
          ]
        },
        {
          field: 'col-provide-agent',
          label: '下级代理',
          group: [
            {
              field: 'profitSonUserId',
              label: '代理ID'
            },
            {
              field: 'profitSonUserPhone',
              label: '手机号'
            },
            {
              field: 'profitSonRate',
              label: '结算价'
            },
            {
              field: 'sonExtFee',
              label: '额外手续费'
            }
          ]
        },
        {
          field: 'remark',
          label: '备注',
          width: 160
        },
        {
          field: 'col-time',
          label: '时间',
          width: 240,
          group: [
            {
              field: 'tradeDate',
              label: '交易'
            },
            {
              field: 'createTime',
              label: '创建'
            },
            {
              field: 'updateTime',
              label: '修改'
            }
          ]
        }
      ]
    };
    return { tableOpts };
  }
});
